html, body, .App, #root{
  height: 100%
}

.App {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #333437;
  justify-content: center;
  flex-wrap: wrap;
}

.musicplayer-container{
  position: relative;
  width: min-content;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}

.slowreverb-controls {
  position: relative;
  width: min-content;
  height: 100%;
  display: flex;
  align-items: center;
}

.circle {
  border-radius: 50%; /* this makes it a circle */
}

.inner-disc{
  position: relative;
  height: 10vw;
  width: 10vw;
  background-color: #262626;
}

.track-name{
  position: absolute;
  width: 100%;
  text-align: center;
  top: -8vw;
  font-size: 1vw;
  font-family: 'SF Mono';
  color: #636669;
  font-weight: bold;
}

.pink{
  color: #FF007A;
}

.sauceMeUp{
    cursor: pointer;
  background-color: #FF007A;
  padding: 0.7vw;
  margin-bottom: 1vw;
  width: fit-content;
  height: fit-content;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
  
.chooseFile{
  cursor: pointer;
  background-color: #E2B713;
  padding: 0.7vw;
  width: fit-content;
  height: fit-content;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}


.chooseFile:active {
    -webkit-box-shadow: inset 0px 0px 5px #c1c1c1;
     -moz-box-shadow: inset 0px 0px 5px #c1c1c1;
          box-shadow: inset 0px 0px 5px #c1c1c1;
   outline: none;
}
.sauceMeUp:active {
    -webkit-box-shadow: inset 0px 0px 5px #c1c1c1;
     -moz-box-shadow: inset 0px 0px 5px #c1c1c1;
          box-shadow: inset 0px 0px 5px #c1c1c1;
   outline: none;
  
}
.chooseFile-text{
  font-family: 'SF Mono';
  color: black;
  font-size: 0.9vw;
  font-weight: 700;
  width: 100%;
  height: min-content;
  text-align: center;
}

.sauceMeUp-text{
  font-family: 'SF Mono';
  color: #D1D0C5;
  font-size: 0.9vw;
  font-weight: 700;
  width: 100%;
  height: min-content;
  text-align: center;
}

.chooseFile-subtext{
  font-family: 'SF Mono';
  color: #636669;
  font-size: 0.7vw;
  font-weight: bold;
  margin-left: 0.2vw;
  margin-top: 0.5vw;
}


.Disc {
  height: 33vw;
  width: 33vw;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  background-color: #D1D0C5;
  border-radius: 50%;
}

.rotate {
    animation: rotate var(--rotation-duration) linear infinite;
}

.stick{ 
  position: absolute;
  top: -10vw;
  left: 15vw;
  z-index: 10; /* This ensures the stick is above the disc */
  width: 40vw; 

  /* Add more styles if needed */
  animation: swing 2s infinite ;
}

.vinyl-player{
  width: 100%;
  position: relative;
  zoom: 90%;
  margin-right: 60px;
}

.slider1-container{
  margin-right: 2vw;
  margin-left: 2vw;
  height: min-content;
  width: fit-content;
  padding: 5px;
  padding-top: 15px;
  background-color: #636669;
  border-radius: 5px;
  display: flex;
  flex-flow: wrap;
  justify-content: center;
}

.slider2-container{
  height: min-content;
  width: fit-content;
  padding: 5px;
  padding-top: 15px;
  background-color: #2C2E31;
  border-radius: 5px;
  display: flex;
  flex-flow: wrap;
  justify-content: center;
}

.slider-container{
  width: 100%;
  display: flex;
}

.control-label {
  width: 100%;
  margin-top: 1vw;
  text-align: center;
  font-size: 1vw;
  font-family: 'SF Mono';
  font-weight: 700;
}


@keyframes swing {
  0%, 100% { transform:  rotateZ(-20deg); }
  50% { transform:  rotateZ(10deg); }
}

:root {
  --rotation-duration: 1s; /* Initial value, can be changed via JavaScript */
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.playback-button{
  width: 100%;
  width: 4vw;
  cursor: pointer; 
}


.header-label{
  position: absolute;
  width: 100%;
  height: min-content;
  text-align: left;
}

.header-label-title{
  color: #D1D0C5;
  font-family: 'SF Mono';
  font-weight: 700;
  font-size: 2vw;
  width: 100%;
}

.header-label-subtitle{
  width: 100%;
  color: #636669;
  font-family: 'Share Tech';
  font-weight: 700;
  font-size: 1vw;
}

.header-label-container{
  display: flex;
  justify-content: center;
  margin: 1vw;

 flex-wrap: wrap;
}


@media (min-aspect-ratio: 2.5/1) {
  /* Your CSS styles for when the aspect ratio is greater than 1:2 go here */
  .App{
    zoom: 30%;
    background-color: red;

  }




  /* You can add additional styles for other elements as needed */
}






